<template>
	<panel :show="show" large @close="close">
		<template #title>
			Schedule
		</template>
		<template>
			<form
				v-if="showForm"
				class="panel-form"
				@submit.prevent="onFormSubmit"
			>
				<div class="form-group">
					<div class="table-responsive">
						<table class="table table-condensed">
							<thead>
								<tr>
									<th>Day</th>
									<th>Start</th>
									<th>Day</th>
									<th>End</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<template v-if="schedules.length > 0">
									<schedule-row
										v-for="day in schedules"
										:key="day.id"
										:day="day"
										@day-name:change="onDayNameChange"
										@ends-at:change="onEndsAtChange"
										@starts-at:change="onStartsAtChange"
										@remove="onRemoveRow"
									/>
								</template>
								<tr v-else>
									<td class="text-center" colspan="5">
										<strong>
											Click the button below to add a new
											daily schedule.
										</strong>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="flex justify-center">
						<button
							type="button"
							class="btn btn-outline-primary w-50 mr-2"
							@click.prevent="onAddRow"
						>
							<div class="btn-ripple" />
							<font-awesome-icon
								class="btn-icon"
								:icon="['far', 'plus']"
							/>
							<span class="btn-label">Add Row</span>
						</button>
					</div>
				</div>
				<div class="flex justify-between mb-12">
					<button
						type="button"
						class="btn btn-danger w-50 mr-2"
						@click.prevent="close"
					>
						<div class="btn-ripple" />
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'times-octagon']"
						/>
						<span class="btn-label">Cancel</span>
					</button>
					<button
						ref="last"
						type="submit"
						class="btn btn-outline-success w-50 ml-2"
						:disabled="busy"
						@keydown.tab="onLastElementTab"
					>
						<div class="btn-ripple" />
						<font-awesome-icon
							v-if="busy"
							class="btn-icon fa-spin mr-2"
							:icon="['far', 'circle-notch']"
						/>
						<font-awesome-icon
							v-else
							class="btn-icon"
							:icon="['far', 'check']"
							aria-hidden="true"
						/>
						<span class="btn-label">
							Save
						</span>
					</button>
				</div>
				<div class="form-group">
					<div
						v-if="errors.length"
						class="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md"
						role="alert"
					>
						<div class="flex">
							<div class="py-1">
								<svg
									class="fill-current h-6 w-6 text-red-500 mr-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path
										d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
									/>
								</svg>
							</div>
							<div>
								<p class="font-bold">Error</p>
								<p
									v-for="error in errors"
									:key="error"
									class="text-sm"
								>
									{{ error }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</form>
			<div v-else class="mb-4">
				<div class="mb-6">
					<img
						src="@/assets/img/undraw_empty.svg"
						class="panel-illustration"
					/>
					<h5 class="panel-description text-center text-2xl">
						Your daily schedule is empty.
					</h5>
					<h6 class="panel-description text-lg leading-10">
						Your schedule is used to identify gaps in your calendar.
						This will help guarantee your calendar is always set
						correctly and that every call is handled appropriately.
					</h6>
				</div>
				<div class="flex justify-center">
					<button
						type="button"
						class="btn btn-outlined w-50"
						@click.prevent="onAddRow"
					>
						<div class="btn-ripple" />
						<span class="btn-label">Get Started</span>
					</button>
				</div>
			</div>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import { mapGetters } from 'vuex'
import Str from 'App/Support/Str'
import ScheduleRow from '../ScheduleRow.vue'
import PanelMixin from '@/mixins/PanelMixin'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Panel,
		ScheduleRow,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the form should be displayed.
		 *
		 * @return {Boolean}
		 */
		showForm() {
			return this.schedules.length > 0 || this.original.length > 0
		},

		...mapGetters({ partner: 'partners/active' }),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Fetch the schedules for the given partner.
		 *
		 * @return {void}
		 */
		async fetchSchedules() {
			try {
				const response = await this.$api
					.partners(this.partner.id)
					.schedules()
					.get()

				this.schedules = response.get('data', [])

				this.original = [...response.get('data', [])]
			} catch (e) {
				this.$alert.response(e)
			}
		},

		/**
		 * Get the errors from response.
		 *
		 * @param {ErrorResponse} response
		 * @return {Array}
		 */
		getErrors(response) {
			if (!response.hasErrors()) {
				return []
			}

			const errors = response.errors()

			return Object.keys(errors).map(key => errors[key][0])
		},

		/**
		 * Add a new row to the schedule.
		 *
		 * @return {void}
		 */
		onAddRow() {
			this.schedules.push({
				id: Str.uuid(),
				day_name: 'sunday',
				ends_at: '00:00',
				starts_at: '00:00',
			})
		},

		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			this.busy = true

			try {
				const response = await this.$api
					.partners(this.partner.id)
					.schedules()
					.create(this.schedules)

				this.schedules = response.get('data', [])

				this.$alert.response(response)

				this.close()
			} catch (e) {
				this.$alert.response(e)

				this.errors = this.getErrors(e)
			}

			this.busy = false
		},

		/**
		 * Handle the on day change change event.
		 *
		 * @param {Object} payload.id
		 * @param {Object} payload.value
		 * @return {void}
		 */
		onDayNameChange({ id, value }) {
			const index = this.schedules.findIndex(item => item.id === id)

			if (index !== -1) {
				this.schedules[index].day_name = value
			}
		},

		/**
		 * Handle the on ends at change event.
		 *
		 * @param {Object} payload.id
		 * @param {Object} payload.value
		 * @return {void}
		 */
		onEndsAtChange({ id, value }) {
			const index = this.schedules.findIndex(item => item.id === id)

			if (index !== -1) {
				this.schedules[index].ends_at = value
			}
		},

		/**
		 * Handle the on starts at change event.
		 *
		 * @param {Object} payload.day
		 * @param {Object} payload.value
		 * @return {void}
		 */
		onStartsAtChange({ id, value }) {
			const index = this.schedules.findIndex(item => item.id === id)

			if (index !== -1) {
				this.schedules[index].starts_at = value
			}
		},

		/**
		 * Handle the add row event.
		 *
		 * @return {void}
		 */
		onRemoveRow(id) {
			const index = this.schedules.findIndex(item => item.id === id)

			if (index !== -1) {
				this.schedules.splice(index, 1)
			}

			if (this.schedules.length === 0) {
				this.errors = []
			}
		},
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'EditSchedulePanel',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the show object.
		 *
		 * @return {void}
		 */
		async show() {
			this.errors = []

			if (this.show) {
				await this.fetchSchedules()
			}
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			busy: false,
			errors: [],
			schedules: [],
			original: [],
		}
	},
}
</script>

<template>
	<tr>
		<td>
			<div class="relative">
				<select
					:id="`day-name-${day.id}`"
					v-model="day_name"
					name="day_name"
					placeholder="Select day"
					class="form-input has-trailing-icon"
					:class="[day_name ? 'text-gray-700' : 'text-gray-500']"
				>
					<option :value="null" disabled>
						Select day
					</option>
					<option
						v-for="day in days"
						:key="day.id"
						:value="day.day_name"
					>
						{{ day.label }}
					</option>
				</select>
				<div class="trailing-icon">
					<font-awesome-icon
						class="fill-current h-4 w-4"
						:icon="['far', 'chevron-down']"
					></font-awesome-icon>
				</div>
			</div>
		</td>
		<td>
			<input
				v-model="starts"
				type="time"
				:name="`${day.day_name}_starts_at`"
				class="form-input"
				placeholder="Start Time"
			/>
		</td>
		<td>
			<strong>
				{{ endDay | ucFirst }}
			</strong>
		</td>
		<td>
			<input
				v-model="ends"
				type="time"
				:name="`${day.day_name}_ends_at`"
				class="form-input"
				placeholder="End Time"
			/>
		</td>
		<td>
			<button
				type="button"
				aria-label="Remove row"
				class="btn btn-sm"
				@click.prevent="onRemoveRow(day.id)"
			>
				<div class="btn-ripple"></div>
				<font-awesome-icon
					fixed-width
					:icon="['fal', 'trash-alt']"
					aria-hidden="true"
				/>
			</button>
		</td>
	</tr>
</template>

<script>
import moment from 'moment'

/**
 * The week days.
 *
 * @return {Array}
 */
const defaultDays = [
	{ day_name: 'sunday', label: 'Sunday', starts_at: null, ends_at: null },
	{ day_name: 'monday', label: 'Monday', starts_at: null, ends_at: null },
	{ day_name: 'tuesday', label: 'Tuesday', starts_at: null, ends_at: null },
	{
		day_name: 'wednesday',
		label: 'Wednesday',
		starts_at: null,
		ends_at: null,
	},
	{ day_name: 'thursday', label: 'Thursday', starts_at: null, ends_at: null },
	{ day_name: 'friday', label: 'Friday', starts_at: null, ends_at: null },
	{ day_name: 'saturday', label: 'Saturday', starts_at: null, ends_at: null },
]

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * The row day name value.
		 *
		 * @type {Object}
		 */
		day_name: {
			/**
			 * Get the row day name.
			 *
			 * @return {String}
			 */
			get() {
				return this.day.day_name
			},

			/**
			 * Set the row day name.
			 *
			 * @param {String} value
			 * @return {void}
			 */
			set(value) {
				this.$emit('day-name:change', {
					id: this.day.id,
					value: value ? value : null,
				})
			},
		},

		/**
		 * The row end date value.
		 *
		 * @type {Object}
		 */
		ends: {
			/**
			 * Get the row end date.
			 *
			 * @return {String}
			 */
			get() {
				return this.day.ends_at
			},

			/**
			 * Set the row end date.
			 *
			 * @param {String} value
			 * @return {void}
			 */
			set(value) {
				this.$emit('ends-at:change', {
					id: this.day.id,
					value,
				})
			},
		},

		/**
		 * Get the end day.
		 *
		 * @return {String}
		 */
		endDay() {
			const index = this.days.findIndex(item => {
				return item.day_name === this.day_name
			})

			if (!this.isNextDay) {
				return this.day_name
			}

			return index === 6 ? 'sunday' : this.days[index + 1].day_name
		},

		/**
		 * Determine if the time selected belongs to the current day.
		 *
		 * @return {Boolean}
		 */
		isNextDay() {
			return moment(this.ends, 'h:mma').isSameOrBefore(
				moment(this.starts, 'h:mma')
			)
		},

		/**
		 * The row start date value.
		 *
		 * @type {Object}
		 */
		starts: {
			/**
			 * Get the row start date.
			 *
			 * @return {String}
			 */
			get() {
				return this.day.starts_at
			},

			/**
			 * Set the row start date.
			 *
			 * @param {String} value
			 * @return {void}
			 */
			set(value) {
				this.$emit('starts-at:change', {
					id: this.day.id,
					value,
				})
			},
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the remove row event.
		 *
		 * @param {Number} id
		 * @return {void}
		 */
		onRemoveRow(id) {
			this.$emit('remove', id)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ScheduleRow',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The schedule's day to be displayed.
		 *
		 * @type {Object}
		 */
		day: {
			type: Object,
			required: true,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			days: defaultDays,
		}
	},
}
</script>

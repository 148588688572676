<template>
	<div class="profile-show">
		<div class="flex justify-between">
			<h1 class="profile-name-title">Settings: {{ name }}</h1>
			<button
				class="profile-header-btn"
				data-tooltip="Set Schedule"
				aria-label="Set Schedule"
				data-position="bottom"
				@click.prevent="onScheduleClick"
			>
				<font-awesome-icon
					fixed-width
					:icon="['fal', 'clock']"
					aria-hidden="true"
				/>
			</button>
		</div>
		<form
			v-if="partner"
			class="profile-container"
			@submit.prevent="onSubmit"
		>
			<div class="profile-section profile-form">
				<div class="form-group">
					<span class="form-label">
						Practice Name
					</span>
					<span class="form-plaintext">
						{{ form.name }}
					</span>
				</div>

				<div class="form-group">
					<span class="form-label">
						Forwarding Number
					</span>
					<span class="form-plaintext">
						{{ partner.twilio_phone_number | formatPhoneNumber }}
					</span>
				</div>

				<div class="form-group">
					<label for="office_phone_number" class="form-label">
						Office Phone Number
					</label>
					<input
						id="office_phone_number"
						v-model="form.office_phone_number"
						type="text"
						name="office_phone_number"
						class="form-input"
						placeholder="Phone number"
					/>
				</div>

				<div class="form-group">
					<label for="office_fax_number" class="form-label">
						Office Fax Number
					</label>
					<input
						id="office_fax_number"
						v-model="form.office_fax_number"
						type="text"
						name="office_fax_number"
						placeholder="Fax Number"
						class="form-input"
					/>
				</div>

				<div class="form-group">
					<label for="fallback_email" class="form-label">
						Fallback Notification Email
					</label>
					<input
						id="fallback_email"
						v-model="form.fallback_email"
						required
						type="text"
						name="fallback_email"
						class="form-input"
						placeholder="Fallback notification email"
					/>
				</div>
				<div class="form-group">
					<label for="fallback_phone_number" class="form-label">
						Fallback Notification Phone Number
					</label>
					<input
						id="fallback_phone_number"
						v-model="form.fallback_phone_number"
						required
						type="text"
						name="fallback_phone_number"
						class="form-input"
						placeholder="Notification phone number"
					/>
				</div>
				<div class="form-group">
					<label for="timezone" class="form-label">
						Timezone
					</label>
					<div class="relative">
						<select
							id="timezone"
							v-model="form.timezone"
							name="timezone"
							placeholder="Select a timezone"
							class="form-input"
							:class="[
								form.timezone
									? 'text-gray-700'
									: 'text-gray-500',
							]"
						>
							<option :value="null" disabled
								>Select a timezone</option
							>
							<option
								v-for="timezone in timezones"
								:key="timezone"
								:value="timezone"
							>
								{{ timezone }}
							</option>
						</select>
						<div
							class="pointer-events-none absolute inset-y-0 mb-6 right-0 flex items-center px-2 text-gray-700"
						>
							<font-awesome-icon
								class="fill-current h-4 w-4 mt-4 mr-2"
								:icon="['far', 'chevron-down']"
							></font-awesome-icon>
						</div>
					</div>
				</div>
			</div>
			<div class="address-section profile-form">
				<h2 class="address-name-title">
					Address
					<font-awesome-icon
						class="address-icon"
						:icon="['far', 'address-card']"
					/>
				</h2>
				<div class="form-group">
					<label for="address_1" class="form-label">
						Street Address
					</label>
					<input
						id="address_1"
						v-model="form.address_1"
						type="text"
						name="address_1"
						class="form-input"
						placeholder="Street Address"
					/>
				</div>
				<div class="form-group">
					<label for="address_2" class="form-label">
						Address Line 2
					</label>
					<input
						id="address_2"
						v-model="form.address_2"
						type="text"
						name="address_2"
						class="form-input"
						placeholder="Address Line 2"
					/>
				</div>
				<div class="form-group">
					<label for="city" class="form-label">
						City
					</label>
					<input
						id="city"
						v-model="form.city"
						type="text"
						name="city"
						class="form-input"
						placeholder="City"
					/>
				</div>
				<div class="form-group">
					<label for="state_province" class="form-label">
						State Or Province
					</label>
					<input
						id="state_province"
						v-model="form.state_province"
						type="text"
						name="state_province"
						class="form-input"
						placeholder="State Or Province"
					/>
				</div>
				<div class="form-group">
					<label for="country" class="form-label">
						Country
					</label>
					<input
						id="country"
						v-model="form.country"
						type="text"
						name="country"
						class="form-input"
						placeholder="Country"
					/>
				</div>
				<div class="form-group">
					<label for="postal_code" class="form-label">
						Postal Code
					</label>
					<input
						id="postal_code"
						v-model="form.postal_code"
						type="text"
						name="postal_code"
						class="form-input"
						placeholder="Postal Code"
					/>
				</div>
				<div class="flex justify-end">
					<button type="submit" class="btn btn-outline-success ml-2">
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'paper-plane']"
						/>
						<span class="btn-label">
							Save
						</span>
					</button>
				</div>
			</div>
		</form>
		<edit-schedule-panel :show="panel" @close="panel = false" />
	</div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import EditSchedulePanel from '@/components/Panels/EditSchedulePanel.vue'

/**
 * Get the empty partner form object.
 *
 * @return {Object}
 */
const getFormObject = () => {
	return {
		name: '',
		office_phone_number: '',
		office_fax_number: '',
		address_1: '',
		address_2: '',
		city: '',
		state_province: '',
		country: '',
		postal_code: '',
		fallback_email: '',
		fallback_phone_number: '',
		timezone: '',
	}
}

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		EditSchedulePanel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the partner's name.
		 *
		 * @return {String}
		 */
		name() {
			return this.partner?.name || ''
		},

		...mapGetters({
			partner: 'partners/active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Get the form field names/keys.
		 *
		 * @return {Array}
		 */
		fields() {
			return Object.keys(this.form)
		},

		/**
		 * Fill the form with the partner's information.
		 *
		 * @return {void}
		 */
		fillForm() {
			if (!this.partner) {
				return this.reset()
			}

			this.fields().forEach(key => (this.form[key] = this.partner[key]))
		},

		/**
		 * Handle the schedule click event.
		 *
		 * @return {void}
		 */
		onScheduleClick() {
			this.panel = true
		},

		/**
		 * Handle the on submit event.
		 *
		 * @return {void}
		 */
		onSubmit() {
			try {
				this.update({
					...this.form,
					id: this.partner.id,
				})

				this.$alert.success('partners.success')
			} catch (e) {
				this.$alert.error('partners.error')
			}
		},

		/**
		 * Reset the form back to its initial state.
		 *
		 * @return {void}
		 */
		reset() {
			this.form = getFormObject()
		},

		...mapActions({ update: 'partners/update' }),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Index',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the active partners.
		 */
		partners: {
			immediate: true,
			handler: 'fillForm',
		},

		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			if (to.params.partner !== from.params.partner) {
				this.reset()
				this.fillForm()
			}
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.timezones = moment.tz.zonesForCountry('us')
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			form: getFormObject(),
			panel: false,
			timezones: [],
		}
	},
}
</script>
